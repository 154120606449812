import styled from 'styled-components'

import Icon from '../Icon'

export const ScoreStarBlock = styled.div`
  display: flex;
  align-items: center;
`

export const StarIconOn = styled(Icon)`
  color: ${({ theme }) => theme.colors.pinkSecondary};
`

export const StarIconOff = styled(Icon)`
  color: ${({ theme }) => theme.colors.greyContour};
`

export const FractionScore = styled.p`
  ${({ theme }) => theme.textStyles.bebasXl};
  color: ${({ theme }) => theme.colors.greyContour};
  margin-left: 2rem;
`

export const FractionScoreLeft = styled.span`
  ${({ theme }) => theme.textStyles.bebasXl};
  color: ${({ theme }) => theme.colors.pinkSecondary};
`
