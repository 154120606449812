import styled from 'styled-components'
import Image from 'next/image'

import ScoreStar from '../ScoreStar'
import ActionButton from '../ActionButton'
import Link from '../Link'

export const ProductCardInformationsBlock = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`

export const ProductCard = styled(Link)`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.greyBorder2};
  &:hover {
    border-color: ${({ theme }) => theme.colors.greyContour};
    box-shadow: 5px 12px 38px rgba(167, 177, 192, 0.2);
  }
`

export const ProductCardImageContainer = styled.div`
  padding: 2rem 2rem 0;
  width: 100%;
  min-height: 24rem;
  display: flex;
  align-items: center;
`

export const ProductCardImage = styled(Image)`
  max-width: 100%;
  height: auto;
  margin: 0 auto;
`

export const ProductCardCategory = styled.h4`
  ${({ theme }) => theme.textStyles.bebasXs};
  color: ${({ theme }) => theme.colors.taupeGray};
  text-transform: uppercase;
  margin-bottom: 0;
`

export const ProductCardTitle = styled.h3`
  ${({ theme }) => theme.textStyles.firaSansXl};
  color: ${({ theme }) => theme.colors.bluePrimary};
  font-weight: 600;
  margin: 0 0 1rem;
`

export const ProductCardDescription = styled.p`
  margin-bottom: 1rem;
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.taupeGray};
`

export const ProductCardScorePriceBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`

export const PriceBlock = styled.div`
  display: flex;
  gap: 1.3rem;
  align-items: center;
`

export const ProductCardScore = styled(ScoreStar)``

export const PriceLeft = styled.div``

export const PriceWithOutReduction = styled.span`
  color: ${({ theme }) => theme.colors.pinkPrimary};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.2;
`

export const PriceDiscountLeft = styled.p`
  color: ${({ theme }) => theme.colors.pinkPrimary};
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
`

export const PriceLabelLeft = styled.p`
  color: ${({ theme }) => theme.colors.bluePrimary};
  ${({ theme }) => theme.textStyles.firaSansXs};
`

export const PriceRight = styled.div``

export const PriceWithReduction = styled.span`
  color: ${({ theme }) => theme.colors.bluePrimary};
  ${({ theme }) => theme.textStyles.bebas2Xl};
  &:after {
    content: attr(data-prefix);
    font-size: 1rem;
    vertical-align: text-top;
  }
`

export const ProductCardCta = styled(ActionButton)`
  width: 100%;
  margin-top: auto;
`
