import React, { FC } from 'react'
import { ImageProps } from 'next/image'

import { ScoreStarProps } from '../ScoreStar'
import { ActionButtonProps } from '../ActionButton'
import { LinkProps } from '../Link'

import * as SC from './styled'

export type ProductCardProps = {
  className?: string
  link?: LinkProps
  category?: string
  title: string
  description?: string
  image?: ImageProps
  score?: ScoreStarProps
  priceWithoutReduction?: string
  priceWithReduction?: string
  priceWithReductionPrefix?: string
  priceLabelLeft?: string
  priceDiscountLeft?: string
  offerButton?: ActionButtonProps
  isLogin?: boolean
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    link,
    category,
    title,
    description,
    image,
    score,
    priceWithoutReduction,
    priceWithReduction,
    priceWithReductionPrefix,
    priceLabelLeft,
    priceDiscountLeft,
    offerButton,
    isLogin,
  } = props

  return (
    <SC.ProductCard {...link}>
      <SC.ProductCardImageContainer>
        {image && <SC.ProductCardImage {...image} />}
      </SC.ProductCardImageContainer>
      <SC.ProductCardInformationsBlock>
        {category && (
          <SC.ProductCardCategory>{category}</SC.ProductCardCategory>
        )}
        <SC.ProductCardTitle>{title}</SC.ProductCardTitle>
        {description && (
          <SC.ProductCardDescription>{description}</SC.ProductCardDescription>
        )}

        <SC.ProductCardScorePriceBlock>
          {isLogin && (
            <>
              {score && <SC.ProductCardScore {...score} />}
              <SC.PriceBlock>
                <SC.PriceLeft>
                  <>
                    {priceWithoutReduction && (
                      <SC.PriceWithOutReduction>
                        <s>{priceWithoutReduction}</s>
                      </SC.PriceWithOutReduction>
                    )}
                    {priceDiscountLeft && (
                      <SC.PriceDiscountLeft>
                        {priceDiscountLeft}
                      </SC.PriceDiscountLeft>
                    )}
                    {priceLabelLeft && (
                      <SC.PriceLabelLeft>{priceLabelLeft}</SC.PriceLabelLeft>
                    )}
                  </>
                </SC.PriceLeft>
                <SC.PriceRight>
                  {priceWithReduction && (
                    <SC.PriceWithReduction
                      data-prefix={priceWithReductionPrefix}
                    >
                      {priceWithReduction}
                    </SC.PriceWithReduction>
                  )}
                </SC.PriceRight>
              </SC.PriceBlock>
            </>
          )}
          {offerButton && <SC.ProductCardCta {...offerButton} />}
        </SC.ProductCardScorePriceBlock>
      </SC.ProductCardInformationsBlock>
    </SC.ProductCard>
  )
}

export default ProductCard
