import React, { FC } from 'react'
import Rating from 'react-rating'

import { Icons } from '../Icon'

import * as SC from './styled'

export type ScoreStarProps = {
  className?: string
  totalStars: number
  activeStars: number
}

const ScoreStar: FC<ScoreStarProps> = ({ totalStars, activeStars }) => {
  return (
    <SC.ScoreStarBlock>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Rating
        initialRating={activeStars}
        placeholderRating={totalStars}
        readonly
        emptySymbol={<SC.StarIconOff icon={Icons.starFull} height={20} />}
        fullSymbol={<SC.StarIconOn icon={Icons.starFull} height={20} />}
      />
      {activeStars && (
        <SC.FractionScore>
          <SC.FractionScoreLeft>{activeStars}</SC.FractionScoreLeft>/
          {totalStars}
        </SC.FractionScore>
      )}
    </SC.ScoreStarBlock>
  )
}

export default ScoreStar
